import React, { useState, useContext } from "react";
import { Button, Card, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import TravelContext from "./TravelContext";
import Dialog from "./Dialog";
import Pin from "./Pin";
import { CheckIfExist } from "./Public";

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

export default function View(props) {
  const { pin } = props,
    { loading, DetectPrice } = useContext(TravelContext),
    [showModal, setShowModal] = useState(false),
    [showDialog, setShowDialog] = useState(false),
    [activePin, setActivePin] = useState(null),
    [after, setAfter] = useState(null),
    [target, setTarget] = useState(null);

  const DialogModal = (showDialog, activePin = null) => {
    setShowDialog(showDialog);
    setActivePin(activePin);
  };

  const AddPinModal = (show, num = null, tar = null) => {
    setShowModal(show);
    setAfter(num);
    setTarget(tar);
    setActivePin(null);
  };

  const EditPinModal = (show, obj) => {
    setShowModal(show);
    setActivePin(obj);
  };

  if (pin === null) {
    return (
      <div className="pin">
        <div className="card-wrap d-flex align-items-center justify-content-center">
          {!loading && (
            <Button
              variant="outline-dark font-weight-bold"
              onClick={() => AddPinModal(true, "", "bottom")}
            >
              Add event <FontAwesomeIcon icon={faPlus} />
            </Button>
          )}
          {showModal && (
            <Pin
              AddPinModal={AddPinModal}
              show={showModal}
              activePin={activePin}
              after={after}
              target={target}
            />
          )}
        </div>
      </div>
    );
  } else {
    const [year, month, day] = pin.date.split("-");
    const obj = DetectPrice(pin);

    return (
      <>
        <div className="pin">
          <div className="card-wrap d-flex align-items-center justify-content-center m-1">
            <Card className="card-inner ml-0 mr-2 mb-2 mt-2" variant="outlined">
              {CheckIfExist(pin, "address") ? (
                <iframe
                  width="273"
                  height="204"
                  className="border-0"
                  loading="lazy"
                  allowFullScreen
                  title={pin.title}
                  src={
                    "https://www.google.com/maps/embed/v1/place?key=AIzaSyBECpvjcvD7qkgMEoQEWjBYbZPh9aN8dbA&q=" +
                    pin.address
                  }
                ></iframe>
              ) : CheckIfExist(pin, "src") ? (
                <Card.Img variant="top" src={pin.src} alt={pin.title} />
              ) : null}
              <Card.Body>
                <Card.Subtitle className="mb-2 text-muted">
                  {pin.type}
                  {pin.date !== "" ? (
                    <Badge
                      bg="success"
                      className="sub-badge"
                    >{`${day}/${month}/${year}`}</Badge>
                  ) : null}
                </Card.Subtitle>
                <Card.Title>{pin.title}</Card.Title>
                <Card.Text>
                  {CheckIfExist(pin, "address")
                    ? [pin.address, <br key={1} />]
                    : null}
                  {CheckIfExist(pin, "description")
                    ? [pin.description, <br key={2} />]
                    : null}
                  {CheckIfExist(pin, "website") ? (
                    <a href={pin.website} target="_blank" rel="noreferrer">
                      website
                    </a>
                  ) : null}

                  {CheckIfExist(pin, "price") && pin.price ? (
                    <span className="form-text card-price">
                      &lrm;
                      {obj.originalPrice + " " + obj.originalPriceSymbol}
                      {obj.convertPrice &&
                        " = " + obj.convertPrice + " " + obj.convertPriceSymbol}
                      &lrm;
                    </span>
                  ) : null}
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <FontAwesomeIcon
                  icon={faTrash}
                  role="button"
                  className="mr-4"
                  onClick={() => {
                    DialogModal(true, pin.id);
                  }}
                />
                <FontAwesomeIcon
                  icon={faPen}
                  role="button"
                  className="mr-4"
                  onClick={() => EditPinModal(true, props)}
                />
              </Card.Footer>
            </Card>
            <div className="card--button d-flex align-items-center justify-content-center ml-1 mr-0">
              <FontAwesomeIcon
                role="button"
                icon={faPlus}
                onClick={() => AddPinModal(true, pin.id, "right")}
              />
            </div>
          </div>
          <div className="card--button d-flex align-items-center justify-content-center m-1">
            <FontAwesomeIcon
              role="button"
              className="ml-n3"
              icon={faPlus}
              onClick={() => AddPinModal(true, pin.id, "bottom")}
            />
          </div>
        </div>
        {showDialog && (
          <Dialog
            DialogModal={DialogModal}
            show={showDialog}
            activePin={activePin}
          />
        )}
        {showModal && (
          <Pin
            AddPinModal={AddPinModal}
            show={showModal}
            activePin={activePin}
            after={after}
            target={target}
          />
        )}
      </>
    );
  }
}
