import React, { useContext } from "react";
import { CardGroup, Row, Col, Form } from "react-bootstrap";
import TravelContext from "./TravelContext";
import View from "./View";

export default function ListPins() {
  const { ChangeTitle, travel, loading, title } = useContext(TravelContext);

  return (
    <>
      <Row>
        <Col>
          <Form.Control
            onBlur={e => ChangeTitle(e)}
            className={"h1 title" + (loading ? " d-none" : "")}
            placeholder="Your Title"
            defaultValue={title}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {travel.length && !loading ? (
            travel.map((element, i) => (
              <CardGroup key={i} className="place w-100">
                {element.map(pinId => {
                  return <View key={pinId.id} pin={pinId} />;
                })}
              </CardGroup>
            ))
          ) : (
            <CardGroup key={0}>
              <View key={0} pin={null} />
            </CardGroup>
          )}
        </Col>
      </Row>
    </>
  );
}
