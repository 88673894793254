import React from "react";
import { Spinner } from "react-bootstrap";
import Chart from "react-google-charts";
import TravelContext from "./TravelContext";

export default class PieChart extends React.Component {
  render() {
    const { travel, DetectPrice } = this.context;

    let nameArr = [],
      priceArr = [],
      pieArr = [["Type", "Price"]];

    travel.map(row => {
      row.map(col => {
        if (col.hasOwnProperty("price") && col.price && col.price > 0) {
          const obj = DetectPrice(col),
            price = obj.convertPrice ? obj.convertPrice : obj.originalPrice,
            type = col.type === "" ? "Other" : col.type;

          if (nameArr.includes(type)) {
            priceArr[nameArr.indexOf(type)] += price;
          } else {
            nameArr.push(type);
            priceArr.push(price);
          }
        }

        return col;
      });

      return row;
    });

    for (let a = 0; a < nameArr.length; a++) {
      pieArr.push([nameArr[a], priceArr[a]]);
    }

    return pieArr.length > 1 ? (
      <Chart
        chartType="PieChart"
        className="pie mr-3 mt-5"
        loader={
          <Spinner animation="border" variant="success" className="m-5" />
        }
        data={pieArr}
        options={{
          title: "Spend"
        }}
        rootProps={{ "data-testid": "1" }}
      />
    ) : null;
  }
}
PieChart.contextType = TravelContext;
