import React, { useContext, useState, useRef } from "react";
import { Modal, Button, Col, InputGroup, Form } from "react-bootstrap";
import TravelContext from "./TravelContext";
import { Money, GetSymbol } from "./Public";
import "../App.css";

export default function MyAccount(props) {
  const {
      currency,
      userDetails,
      budget,
      convert,
      UpdateMyAccount
    } = useContext(TravelContext),
    [convertToCurrency, setConvertToCurrency] = useState(null),
    [defaultCurrency, setDefaultCurrency] = useState(null),
    ref = useRef(budget),
    show = props.show;

  const HandleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    let obj = {
      budget: form.elements.budget.value,
      currency: form.elements.currency.value,
      convert: form.elements.convert.value ? form.elements.convert.value : null
    };

    UpdateMyAccount(obj);
    Close();
  };

  const Close = () => {
    props.setShow(false);
  };

  const ChangeCurrency = e => {
    setDefaultCurrency(e.target.value);
  };

  const ChangeConvertCurrency = e => {
    setConvertToCurrency(e.target.value);
  };

  const IsNumberKey = e => {
    ref.current.value = e.target.value.replace(/\D/g, "");
  };

  const dontShow = defaultCurrency ? defaultCurrency : currency;

  let tempIcon = GetSymbol(currency).show;
  if (convert && convertToCurrency !== "") {
    tempIcon = GetSymbol(convert).show;
  }
  if (defaultCurrency) {
    tempIcon = GetSymbol(defaultCurrency).show;
  }
  if (convertToCurrency) {
    tempIcon = GetSymbol(convertToCurrency).show;
  }
  return (
    <Modal animation={false} show={show} onHide={() => Close()}>
      <Modal.Header closeButton>
        <Modal.Title>My Account</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={HandleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                defaultValue={userDetails.attributes.email}
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="currency">
              <Form.Label>Default currency</Form.Label>
              <Form.Control
                as="select"
                onChange={e => ChangeCurrency(e)}
                custom
                defaultValue={currency}
              >
                {Money.map((element, i) => (
                  <option key={i} value={element.name}>
                    {element.show}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="convert">
              <Form.Label>Convert to</Form.Label>
              <Form.Control
                as="select"
                onChange={e => ChangeConvertCurrency(e)}
                custom
                defaultValue={convert}
              >
                <option value="">Don't convert</option>
                {Money.map((element, i) => {
                  return dontShow === element.name ? null : (
                    <option key={i} value={element.name}>
                      {element.show}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="budget">
              <Form.Label>Your budget</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>{tempIcon}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  defaultValue={budget}
                  ref={ref}
                  onKeyUp={e => IsNumberKey(e)}
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => Close()}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
