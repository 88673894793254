import { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import MyAccount from "./MyAccount";

export default function Header(props) {
  const [show, setShow] = useState(false);
  return (
    <header className="login">
      <Button
        variant="outline-dark"
        className="mr-3"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faUser} />
      </Button>
      <Button variant="outline-dark" onClick={props.signOut}>
        Sign out
      </Button>
      {show && <MyAccount show={show} setShow={setShow} />}
    </header>
  );
}
