import React, { useContext, useEffect, useState } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import TravelContext from "./TravelContext";
import { GetSymbol } from "./Public";

export default function Total(props) {
  const {
      loading,
      travel,
      budget,
      currency,
      convert,
      GetSetRate,
      DetectPrice
    } = useContext(TravelContext),
    priceSymbol = convert ? GetSymbol(convert).show : GetSymbol(currency).show,
    rateSymbol = convert ? GetSymbol(convert).show : null,
    rate = GetSetRate("get"),
    [totalPrice, setTotalPrice] = useState(null);

  let variant = null,
    left = 0;

  const CalcTotal = list => {
    let price = 0;

    list.forEach(element => {
      element.forEach(pinId => {
        if (pinId.hasOwnProperty("price") && pinId.price) {
          if (convert && rate) {
            if (pinId.hasOwnProperty("currency")) {
              if (
                rate.hasOwnProperty(pinId.currency) &&
                rate[pinId.currency].hasOwnProperty(convert)
              ) {
                const obj = DetectPrice(pinId);
                //console.log(obj);
                price += obj.convertPrice;
              }
            } else {
              const obj = DetectPrice(pinId);
              //console.log(obj);
              price += obj.convertPrice;
            }
          } else {
            price += parseInt(pinId.price);
          }
        }
      });
    });
    // console.log(price);
    return price.toFixed(2);
  };

  useEffect(() => {
    const calc = CalcTotal(travel);
    setTotalPrice(calc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calc = CalcTotal(travel);
    setTotalPrice(calc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate, currency, convert, travel]);

  let totalConverted = convert ? totalPrice : null;
  if (!isNaN(budget) && budget > 0) {
    left = budget - totalPrice;
    if (left >= 0) {
      variant = "info";
    } else {
      variant = "danger";
    }
  }
  if (totalPrice > 0 && !loading) {
    return (
      <Row className="mt-5" xs={1} md={1} lg={1} xl={2}>
        <Col>
          <Alert className="mr-3" variant="success">
            <strong>Total Price:</strong>
            <Alert.Heading>
              {!convert && totalPrice + " " + priceSymbol}
              {totalConverted ? totalConverted + " " + rateSymbol : null}
            </Alert.Heading>
          </Alert>
        </Col>
        {variant && (
          <Col>
            <Alert className="mr-3" variant={variant}>
              <strong>Budget Left:</strong>
              <Alert.Heading>
                {left.toFixed(2) + " " + priceSymbol}
              </Alert.Heading>
            </Alert>
          </Col>
        )}
      </Row>
    );
  } else return null;
}
