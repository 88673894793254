import React from "react";
import { Modal, Button } from "react-bootstrap";
import TravelContext from "./TravelContext";

export default function Dialog(props) {
  return (
    <TravelContext.Consumer>
      {context => (
        <Modal
          animation={false}
          show={props.show}
          onHide={() => {
            props.DialogModal(false, null);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure ?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                props.DialogModal(false, null);
              }}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                context.RemovePin(props.activePin);
                props.DialogModal(false, null);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </TravelContext.Consumer>
  );
}
