import React, { lazy, Suspense } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { TravelProvider } from "./components/TravelProvider";
import awsExports from "./aws-exports";
import ListPins from "./components/ListPins";
import Header from "./components/Header";
import Total from "./components/Total";
import PieChart from "./components/PieChart";

import "@aws-amplify/ui-react/styles.css";
import "./App.css";

const Footer = lazy(() => import("./components/Footer"));

Amplify.configure(awsExports);

const Main = ({ signOut, user }) => {
  return (
    <React.StrictMode>
      <TravelProvider user={user}>
        <Header signOut={signOut} />
        <ListPins />
        <Row>
          <Col lg="4">
            <Total />
          </Col>
          <Col lg="4">
            <PieChart />
          </Col>
        </Row>
      </TravelProvider>
    </React.StrictMode>
  );
};

const App = () => {
  return (
    <div className="App">
      <Container fluid className="main">
        <Row>
          <Col>
            <h1 className="logo">
              <FontAwesomeIcon icon={faGlobeAmericas} className="mr-2" />
              Travel React
            </h1>
          </Col>
        </Row>
        {withAuthenticator(Main)()}
      </Container>
      <Suspense
        fallback={
          <Spinner animation="border" variant="success" className="spinner" />
        }
      >
        <Footer />
      </Suspense>
    </div>
  );
};

export default App;
