export const Money = [
  {
    name: "EUR",
    full: "EURO",
    symbol: "€",
    place: "Europe",
    show: "EUR"
  },
  {
    name: "USD",
    full: "United States Dollar",
    symbol: "$",
    place: "USA",
    show: "USD"
  },
  {
    name: "CAD",
    full: "Canadian dollar",
    symbol: "$",
    place: "Canada",
    show: "CAD"
  },
  {
    name: "GBP",
    full: "Pound sterling",
    symbol: "£",
    place: "United Kingdom",
    show: "GBP"
  },
  {
    name: "ILS",
    full: "Israeli shekel",
    symbol: "₪",
    place: "Israel",
    show: "ILS"
  },
  {
    name: "RUB",
    full: "Russian ruble",
    symbol: "₽",
    place: "Russia",
    show: "RUB"
  },
  {
    name: "UAH",
    full: "Ukrainian hryvnia",
    symbol: "₴",
    place: "Ukraine",
    show: "UAH"
  },
  {
    name: "AUD",
    full: "Australian Dollar",
    symbol: "$",
    place: "Australia",
    show: "AUD"
  },
  {
    name: "NZD",
    full: "New Zealand Dollar",
    symbol: "$",
    place: "New Zealand",
    show: "NZD"
  },
  {
    name: "AED",
    full: "United Arab Emirates Dirham",
    symbol: "د.إ",
    place: "United Arab Emirates",
    show: "AED"
  },
  {
    name: "CHF",
    full: "Swiss franc",
    symbol: "CHf",
    place: "Switzerland",
    show: "CHF"
  },
  {
    name: "PLN",
    full: "Polish złoty",
    symbol: "zł",
    place: "Poland",
    show: "PLN"
  },
  {
    name: "THB",
    full: "Thai Baht",
    symbol: "฿",
    place: "Thailand",
    show: "THB"
  }
];

export const GetSymbol = name => {
  return Money.filter(Money => name === Money.name)[0];
};

export const JsonParse = json => {
  return JSON.parse(JSON.stringify(json));
};

export const FindElement = (arr, pinId) => {
  let row = null,
    col = null,
    rowCount = -1,
    colCount = -1;

  arr.map(element => {
    rowCount++;
    colCount = -1;
    element.map(elementCol => {
      colCount++;
      if (elementCol.id === pinId) {
        row = rowCount;
        col = colCount;
      }
      return elementCol;
    });

    return element;
  });

  return [row, col];
};

export const FetchData = currency => {
  return fetch(
    "https://pk9ar5ce38.execute-api.us-east-1.amazonaws.com/default/travelreact10b94b63-staging?currency=" +
      currency
  );
};

export const CheckIfExist = (obj, p) => {
  return obj.hasOwnProperty(p) && obj[p];
};
