import React, { Component } from "react";
import { Modal, Button, Col, InputGroup, Form } from "react-bootstrap";
import TravelContext from "./TravelContext";
import { Money, GetSymbol } from "./Public";

import "../App.css";

export default class Pin extends Component {
  state = {
    validated: false,
    icon: null,
    changeConvert: null,
    title: ""
  };

  HandleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ title: this.state.title.trim() });
    const form = event.currentTarget,
      context = this.context;

    let obj = {
      title: form.elements.title.value,
      address: form.elements.address.value,
      type: form.elements.type.value,
      description: form.elements.description.value,
      date: form.elements.date.value,
      website: form.elements.website.value,
      src: form.elements.src.value,
      price: form.elements.price.value
    };

    if (context.convert) {
      obj.currency = form.elements.currency.value;
    }

    if (this.state.title.trim() !== "") {
      let { activePin } = this.props;
      if (typeof activePin === "object" && activePin !== null) {
        obj.id = form.elements.id.value;
        context.EditPin(obj);
        //  console.log(obj);
      } else {
        context.AddPin(obj, this.props.after, this.props.target);
      }

      this.Close();
    } else {
      this.setState({ validated: true });
    }
  };

  Close = () => {
    this.setState({ validated: false });
    this.props.AddPinModal(false);
  };

  TrimSpace = event => {
    this.setState({ title: event.target.value });
  };

  ChangeCurrency = e => {
    this.setState({
      icon: GetSymbol(e.target.value).show,
      changeConvert: e.target.value
    });
  };

  componentDidMount() {
    let { activePin } = this.props;
    if (typeof activePin === "object" && activePin !== null) {
      this.setState({ title: activePin.pin.title });
    }
  }

  render() {
    let { title, validated, icon } = this.state,
      { show, activePin } = this.props,
      symbol = this.context.GetSymbol(this.context.currency).show,
      currency = this.context.currency,
      action = "",
      address = "",
      price = "",
      type = "",
      date = "",
      description = "",
      id = "",
      website = "",
      src = "";

    if (show) {
      if (typeof activePin === "object" && activePin !== null) {
        let { pin } = activePin;
        id = pin.id;
        title = this.state.title;
        address = pin.address;
        price = pin.price;
        type = pin.type;
        date = pin.date;
        description = pin.description;
        website = pin.website;
        src = pin.src;
        symbol =
          pin.hasOwnProperty("currency") && pin.currency
            ? this.context.GetSymbol(pin.currency).show
            : symbol;
        currency =
          pin.hasOwnProperty("currency") && pin.currency
            ? pin.currency
            : currency;
        action = "edit";
      } else {
        action = "add";
      }
    }

    return (
      <Modal animation={false} show={show} onHide={() => this.Close()}>
        <Modal.Header closeButton>
          <Modal.Title>Add Pin</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={this.HandleSubmit}>
          <Modal.Body>
            {action === "edit" ? (
              <Form.Control id="id" type="hidden" defaultValue={id} />
            ) : null}
            <Form.Row>
              <Form.Group as={Col} controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  value={title}
                  onChange={e => this.TrimSpace(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Required field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="date">
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" defaultValue={date} />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control defaultValue={address} />
            </Form.Group>

            <Form.Group controlId="website">
              <Form.Label>Website</Form.Label>
              <Form.Control defaultValue={website} />
            </Form.Group>

            <Form.Group controlId="src">
              <Form.Label>Image URL</Form.Label>
              <Form.Control defaultValue={src} />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId="type">
                <Form.Label>Type</Form.Label>
                <Form.Control as="select" custom defaultValue={type}>
                  <option value=""></option>
                  <optgroup label="Stay">
                    <option>Hotel</option>
                    <option>Appartment</option>
                    <option>Hostel</option>
                    <option>Camping</option>
                  </optgroup>
                  <optgroup label="Transfer">
                    <option>Flight</option>
                    <option>Bus</option>
                    <option>Taxi</option>
                    <option>Boat</option>
                  </optgroup>
                  <optgroup label="Eat">
                    <option>Restaurant</option>
                    <option>Lounge</option>
                  </optgroup>
                </Form.Control>
              </Form.Group>

              {this.context.convert && (
                <Form.Group as={Col} controlId="currency">
                  <Form.Label>Default currency</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => this.ChangeCurrency(e)}
                    custom
                    defaultValue={currency}
                  >
                    {Money.map((element, i) => (
                      <option key={i} value={element.name}>
                        {element.show}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Form.Group as={Col} controlId="price">
                <Form.Label>Price</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>{icon ? icon : symbol}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control defaultValue={price} />
                </InputGroup>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} defaultValue={description} />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.Close()}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
Pin.contextType = TravelContext;
