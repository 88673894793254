import { useState, useEffect } from "react";
import { Amplify, Hub } from "@aws-amplify/core";
import { DataStore } from "@aws-amplify/datastore";
import { Spinner } from "react-bootstrap";
import TravelContext from "./TravelContext";
import { GetSymbol, FindElement, JsonParse, FetchData } from "./Public";
import { Travel } from "../models";
import awsconfig from "../aws-exports";

Amplify.configure(awsconfig);

export function TravelProvider(props) {
  const [loading, setLoading] = useState(true),
    [user, setUser] = useState(null),
    [title, setTitle] = useState(null),
    [currency, setCurrency] = useState("USD"),
    [travel, setTravel] = useState([]),
    [budget, setBudget] = useState(null),
    [convert, setConvert] = useState(null),
    [rate, setRate] = useState(null),
    [action, setAction] = useState(null),
    userDetails = props.user,
    userId = userDetails.username;

  useEffect(() => {
    const LoadData = () => {
      DataStore.query(Travel, c => c.user("eq", userId)).then(details => {
        if (details.length) {
          const d = details[0],
            t = d.title ? d.title : null,
            b = d.budget ? d.budget : null,
            r = d.convert ? d.convert : null,
            c = d.currency ? d.currency : currency,
            trav = d.travel ? JsonParse(d.travel) : [];

          let count = 0,
            arrRate = [currency],
            tempRate = rate === null ? {} : rate;

          setUser(userId);
          setTitle(t);
          setCurrency(c);
          setConvert(r);
          setBudget(b);
          setTravel(trav);

          if (trav.length && d.convert) {
            trav.forEach(element => {
              element.forEach(pinId => {
                if (
                  pinId.hasOwnProperty("currency") &&
                  pinId.currency &&
                  pinId.currency !== "" &&
                  !arrRate.includes(pinId.currency)
                ) {
                  arrRate.push(pinId.currency);
                }
              });
            });

            arrRate.forEach(curr => {
              FetchData(curr)
                .then(data => data.json())
                .then(json => {
                  let tempObj = {};
                  tempObj[curr] = json;
                  tempObj = Object.assign(tempRate, tempObj);
                  count++;
                  if (count === arrRate.length) {
                    //  console.log("setRate", tempObj);
                    setRate(tempObj);
                    setLoading(false);
                    setUser(userId);
                  }
                });
            });
          } else {
            setLoading(false);
            setUser(userId);
          }
        } else {
          setUser(userId);
        }
      });
    };

    Hub.listen("datastore", capsule => {
      const {
        payload: { event } // { event, data }
      } = capsule;

      /// console.log("DataStore event", event, data);

      if (event === "ready") {
        LoadData();
        setLoading(false);
      }
    });
    LoadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user !== null && action !== null) {
      DataStore.query(Travel, c => c.user("eq", user)).then(details => {
        if (details.length) {
          // Update
          //  console.log("**Update***", details[0]);
          DataStore.save(
            Travel.copyOf(details[0], updated => {
              updated.travel = travel;
              updated.title = title;
              updated.currency = currency;
              updated.budget = budget;
              updated.convert = convert;
              updated.updateDate = new Date().toISOString();
            })
          );
        } else {
          // Insert
          //  console.log("**Insert***");
          DataStore.save(
            new Travel({
              user: user,
              travel: travel,
              title: title,
              currency: currency,
              convert: convert,
              budget: budget,
              insertDate: new Date().toISOString()
            })
          ); /*.then(async res => {
                            const models = await DataStore.query(Travel);
                            console.log(models);
                        }); */
        }
      });

      setAction(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    let count = 0,
      arrRate = [currency],
      r = rate === null ? {} : rate;

    if (convert === null || convert === "") setRate(null);
    else {
      if (travel.length && convert) {
        travel.forEach(element => {
          element.forEach(pinId => {
            if (
              pinId.hasOwnProperty("currency") &&
              pinId.currency &&
              pinId.currency !== "" &&
              !arrRate.includes(pinId.currency)
            ) {
              arrRate.push(pinId.currency);
            }
          });
        });

        arrRate.forEach(curr => {
          FetchData(curr)
            .then(data => data.json())
            .then(json => {
              let tempObj = {};
              tempObj[curr] = json;
              tempObj = Object.assign(r, tempObj);
              count++;
              if (count === arrRate.length) {
                //  console.log("setRate", tempObj);
                setRate(tempObj);
                setLoading(false);
              }
            });
        });
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, convert]);

  const ChangeTitle = e => {
    if (title !== e.target.value) {
      setTitle(e.target.value);
      UpdateOrInsertDB();
    }
  };

  const AddPin = (obj, after, target) => {
    let currentTimestamp = +new Date(),
      travelList = JsonParse(travel),
      afterPinId = after,
      r = rate === null ? {} : rate,
      position = FindElement(travelList, afterPinId);

    obj.id = "pin" + currentTimestamp;

    if (target === "bottom") {
      travelList.splice(position[0] + 1, 0, [obj]);
    } else {
      travelList[position[0]].splice(position[1] + 1, 0, obj);
    }

    if (
      convert &&
      obj.hasOwnProperty("currency") &&
      obj.currency &&
      (!rate || !rate.hasOwnProperty(obj.currency))
    ) {
      FetchData(obj.currency)
        .then(data => data.json())
        .then(json => {
          let tempObj = {};
          tempObj[obj.currency] = json;
          tempObj = Object.assign(r, tempObj);
          setRate(tempObj);
          setTravel(travelList);
          UpdateOrInsertDB();
        });
    } else {
      setTravel(travelList);
      UpdateOrInsertDB();
    }
  };

  const EditPin = obj => {
    let travelList = JsonParse(travel),
      position = FindElement(travelList, obj.id),
      r = rate === null ? {} : rate,
      tempObj = travelList[position[0]][position[1]];

    tempObj.title = obj.title;
    tempObj.price = obj.price;
    tempObj.date = obj.date;
    tempObj.type = obj.type;
    tempObj.address = obj.address;
    tempObj.description = obj.description;
    tempObj.website = obj.website;
    tempObj.src = obj.src;

    if (convert && obj.hasOwnProperty("currency") && obj.currency) {
      tempObj.currency = obj.currency;

      if (!rate || !rate.hasOwnProperty(obj.currency)) {
        FetchData(obj.currency)
          .then(data => data.json())
          .then(json => {
            let tempObj = {};
            tempObj[obj.currency] = json;
            tempObj = Object.assign(r, tempObj);
            setRate(tempObj);
            travelList[position[0]][position[1]] = tempObj;
            setTravel(travelList);
            UpdateOrInsertDB();
          });
      } else {
        travelList[position[0]][position[1]] = tempObj;
        setTravel(travelList);
        UpdateOrInsertDB();
      }
    } else {
      travelList[position[0]][position[1]] = tempObj;
      setTravel(travelList);
      UpdateOrInsertDB();
    }
  };

  const RemovePin = activePin => {
    var tempList = JsonParse(travel),
      pos = FindElement(tempList, activePin);

    tempList[pos[0]].splice(pos[1], 1);

    if (!tempList[pos[0]].length) {
      tempList.splice(pos[0], 1);
    }

    setTravel(tempList);
    UpdateOrInsertDB();
  };

  const UpdateMyAccount = obj => {
    let change = false;
    if (obj.currency !== currency) {
      setCurrency(obj.currency);
      change = true;
    }
    if (obj.budget !== budget) {
      setBudget(obj.budget);
      change = true;
    }
    if (obj.convert !== convert) {
      setConvert(obj.convert);
      change = true;
    }
    if (change) {
      UpdateOrInsertDB();
    }
  };

  const UpdateOrInsertDB = (type = "sync") => {
    setAction(type);
  };

  const ConvertPrice = (price, from = null, to = null) => {
    from = from ? from : convert;
    let resp = null;

    if (rate && convert && !isNaN(price)) {
      if (to) {
        if (rate.hasOwnProperty(from) && rate[from].hasOwnProperty(to)) {
          resp = (parseInt(price) * rate[from][to]).toFixed(2);
        } else {
          resp = null;
        }
      } else {
        const tempPrice =
          rate.hasOwnProperty(currency) && rate[currency].hasOwnProperty(from)
            ? (parseInt(price) * rate[currency][from]).toFixed(2)
            : null;

        resp = tempPrice;
      }
    }

    //console.log("resp", resp);
    return resp;
  };

  const DetectPrice = pin => {
    let originalPrice = null,
      originalPriceSymbol = null,
      convertPrice = null,
      convertPriceSymbol = null;

    if (pin.hasOwnProperty("price") && pin.price) {
      if (rate && convert) {
        if (pin.hasOwnProperty("currency") && pin.currency) {
          originalPrice = pin.price;
          originalPriceSymbol = GetSymbol(pin.currency).show;

          convertPrice = ConvertPrice(pin.price, pin.currency, convert);
          if (convertPrice) {
            convertPriceSymbol = GetSymbol(convert).show;
          }
        } else {
          originalPrice = pin.price;
          originalPriceSymbol = GetSymbol(currency).show;

          convertPrice = ConvertPrice(pin.price);
          if (convertPrice) {
            convertPriceSymbol = GetSymbol(convert).show;
          }
        }
      } else {
        originalPrice = pin.price;
        originalPriceSymbol = GetSymbol(currency).show;
      }
    }

    originalPrice = originalPrice ? parseFloat(originalPrice) : originalPrice;
    convertPrice = convertPrice ? parseFloat(convertPrice) : convertPrice;

    return {
      originalPrice,
      originalPriceSymbol,
      convertPrice,
      convertPriceSymbol
    };
  };

  const GetSetRate = (action = "get") => {
    if (action === "get") {
      return rate;
    } else {
      setRate(action);
    }
  };

  return (
    <TravelContext.Provider
      value={{
        title,
        travel,
        currency,
        convert,
        userDetails,
        budget,
        loading,
        GetSetRate,
        ChangeTitle,
        RemovePin,
        EditPin,
        AddPin,
        UpdateMyAccount,
        GetSymbol,
        ConvertPrice,
        DetectPrice
      }}
    >
      {loading && (
        <Spinner animation="border" variant="success" className="spinner" />
      )}
      {props.children}
    </TravelContext.Provider>
  );
}
